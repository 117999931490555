// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loading: true,
  mechanics: [],

  query: {
    branches: [],
    is_external: null,
    page: 1,
    pages: 1,
    search: '',
  },
};

const mechanicReducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default mechanicReducer;
