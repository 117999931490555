// api
import listMechanicsApi from '../../api/list.api.mechanic';

// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// mechanic components
import MechanicsList from '../../components/MechanicsList/MechanicsList';

// mechanic events
import showCreateMechanicModalEvent from '../../events/showCreateModal.event.mechanic';
import showUpdateMechanicModalEvent from '../../events/showUpdateModal.event.mechanic';

// mechanic redux actions
import {setQuery as setMechanicsQuery} from '../../redux/actions';

// mechanic sockets
import mechanicCreatedSocket from '../../sockets/created.socket.mechanic';
import mechanicDeletedSocket from '../../sockets/deleted.socket.mechanic';
import mechanicUpdatedSocket from '../../sockets/updated.socket.mechanic';

// uuid
import {v4} from 'uuid';

class MechanicsContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    is_external: PropTypes.bool,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    mechanics: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getMechanics();
    this.props.subscribe(
      mechanicCreatedSocket.subscribe(this.addMechanic),
      mechanicDeletedSocket.subscribe(this.deleteMechanic),
      mechanicUpdatedSocket.subscribe(this.updateMechanic)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getMechanics = async ({
    branches = this.props.query.branches,
    is_external = this.props.query.is_external,
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
  } = {}) => {
    const {dispatch} = this.props;

    const apiId = v4();
    this.apiId = apiId;

    dispatch(setMechanicsQuery({branches, is_external, page, search}));
    this.setState({
      loading: true,
      mechanics: [],
    });
    try {
      const query = this.generateApiQuery({
        branches,
        is_external,
        page,
        perPage,
        search,
      });

      const {results: mechanics, count} = await listMechanicsApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        mechanics,
      });
      dispatch(setMechanicsQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({branches, is_external, page, perPage, search}) => {
    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      ordering: 'first_name',
    };

    if (!!branches.length) query.branch__in = branches.join(',');
    if (!['', null].includes(is_external)) query.is_external = is_external;
    if (!!search) query.search = search;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listMechanicsApi,
      fileName: 'mechanics.csv',
      query: this.generateApiQuery(query),
      parseData: (mechanics) =>
        [...mechanics].map((mechanic) => ({
          ...mechanic,
          branch: getBranchName(mechanic?.branch) || '',
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  addMechanic = (mechanic) => {
    const {loading} = this.props;
    if (loading) return;
    const mechanics = [...this.state.mechanics, mechanic];
    this.setState({mechanics});
  };

  updateMechanic = (mechanic) => {
    const {loading} = this.props;
    if (loading) return;
    const mechanics = [...this.state.mechanics].map((mechanicToUpdate) =>
      mechanicToUpdate.id === mechanic.id ? mechanic : mechanicToUpdate
    );
    this.setState({mechanics});
  };

  deleteMechanic = ({id}) => {
    const {loading} = this.props;
    if (loading) return;
    const mechanics = [...this.state.mechanics].filter(
      (mechanic) => mechanic.id !== id
    );
    this.setState({mechanics});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getMechanics({page});
  };

  search = (search) => this.getMechanics({search, page: 1});

  mechanics = () => this.state.mechanics;

  setFilter = ({key, value}) =>
    this.getMechanics({
      [key]: value,
      page: 1,
    });

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Mechanic',
        icon: 'plus',
        theme: 'black',
        onClick: () => showCreateMechanicModalEvent.publish(),
      },
    ];
  };

  filters = () => {
    const {
      branches,
      query: {branches: selectedBranches, is_external},
    } = this.props;
    return [
      {
        key: 'is_external',
        label: 'External',
        type: 'select',
        value: is_external || '',
        options: [
          {
            value: '',
            label: 'All',
          },
          {
            value: 'true',
            label: 'External',
          },
          {
            value: 'false',
            label: 'Internal',
          },
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'is_external',
            value,
          }),
      },
      {
        key: 'branch',
        label: 'Branch',
        type: 'select',
        value: selectedBranches,
        options: [
          ...branches.map(({id, name}) => ({value: `${id}`, label: name})),
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'branches',
            value,
          }),
      },
    ];
  };

  editMechanic = (mechanic) => () => {
    showUpdateMechanicModalEvent.publish({mechanic});
  };

  render() {
    const {
      query: {page, pages, search},
    } = this.props;
    const {loading} = this.state;
    const mechanics = this.mechanics();
    return (
      <MechanicsList
        actions={this.actions()}
        columns={columns}
        filters={this.filters()}
        loading={loading}
        onMechanic={this.editMechanic}
        onPage={this.setPage}
        onSearch={this.search}
        page={page}
        pages={pages}
        search={search}
        mechanics={mechanics}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      branches: state.branch.branches,
      is_external: state.branch.is_external,
      query: state.mechanic.query,
    }))(MechanicsContainer)
  )
);
