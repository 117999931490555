// branch components
import BranchSelect from '../../../branch/components/BranchSelect/BranchSelect';

// components
import {Modal, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const EditMechanicModal = ({
  branch,
  is_external,
  loading,
  name,
  nickname,
  onChange,
  onClose,
  onSave,
  visible,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Mechanic Editor"
    buttonsRight={[{label: 'Save', onClick: onSave, loading}]}
    size="small"
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput
              value={name}
              disabled={loading}
              onChange={onChange}
              placeholder="Insert name"
            >
              {name.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={nickname}
              disabled={loading}
              onChange={onChange}
              placeholder="Insert nickname"
            >
              {nickname.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={is_external}
              disabled={loading}
              onChange={onChange}
            >
              {is_external.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <BranchSelect
              branch={branch}
              onChange={onChange}
              disabled={loading}
            />
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

EditMechanicModal.propTypes = {
  branch: PropTypes.string,
  is_external: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.object,
  nickname: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditMechanicModal;
