import {TOGGLE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'is_external',
  label: {
    default: 'External',
    short: 'External',
  },
});
