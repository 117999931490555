const extractMechanicToObject = (mechanic) => {
  // const [firstName, ...lastNames] = mechanic.split(' ');
  return {
    nickname: mechanic.nickname,
    first_name: mechanic.name,
    is_external: mechanic.is_external,
    // last_name: lastNames.join(' '),
  };
};

export default extractMechanicToObject;
