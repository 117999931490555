const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'nickname',
    label: 'Nickname',
  },
  {
    key: 'is_external',
    label: 'External',
  },
  {
    key: 'branch',
    label: 'Branch',
    span: 3,
  },
];

export default columns;
