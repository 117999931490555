// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const MechanicsList = ({
  actions = [],
  columns,
  filters = [],
  loading,
  onMechanic,
  onPage,
  onSearch,
  page,
  pages,
  search,
  mechanics,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    filters={filters}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search Mechanics"
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      mechanics.map((mechanic) => (
        <TableRow key={mechanic.id} onClick={onMechanic(mechanic)}>
          {(TableCol) => [
            <TableCol key="name">
              {mechanic.first_name} {mechanic.last_name}
            </TableCol>,
            <TableCol key="nickname">{mechanic.nickname || '-'}</TableCol>,
            <TableCol key="is_external">
              {mechanic.is_external ? 'Yes' : 'No'}
            </TableCol>,
            <TableCol key="branch">
              {getBranchName(mechanic?.branch) || '-'}
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

MechanicsList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  mechanics: PropTypes.array,
};

export default MechanicsList;
